import { Flex } from "@mantine/core";
import React from "react";

export const NotFound: React.FC = () => {
	return (
		<Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
			<h1>Page not found</h1>
		</Flex>
	);
};
