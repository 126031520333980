import React, { ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../../pages/Login/Login";
import Landing from "../../pages/Landing/Landing";
import AppPage from "../../AppPage";
import SystemsLibraryMenu from "../../pages/SystemsLibrary/SystemsLibraryMenu";
import Sensors from "../../pages/SystemsLibrary/sensors/Sensors";
import Emitters from "../../pages/SystemsLibrary/emitters/Emitters";
import Effectors from "../../pages/SystemsLibrary/effectors/Effectors";
import Platforms from "../../pages/SystemsLibrary/platforms/Platforms";
import Systems from "../../pages/SystemsLibrary/systems/Systems";
import ProjectMenu from "../../pages/ProjectMenu/ProjectMenu";
import Assessments from "../../pages/Assessments/Assessments";
import ForcePackages from "../../pages/ForcePackages/ForcePackages";
import ForcePackagesGraphPage from "../../pages/ForcePackages/force-package-graph/ForcePackageGraphPage";
import ResourcesMenu from "../../pages/ResourcesMenu/ResourcesMenu";
import SymbolsPage from "../../pages/SymbolsPage/SymbolsPage";
import ProtectedRoute from "./ProtectedRoute";
import AppInfoContextProvider from "../contexts/AppInfoContext";
import { NotFound } from "../common/NotFound";

interface ProtectedAppPageProps {
	children: ReactNode;
}

const ProtectedAppPage: React.FC<ProtectedAppPageProps> = ({ children }) => (
	<AppInfoContextProvider>
		<ProtectedRoute>
			<AppPage>{children}</AppPage>
		</ProtectedRoute>
	</AppInfoContextProvider>
);

const Router: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						<ProtectedAppPage>
							<Landing />
						</ProtectedAppPage>
					}
				/>

				<Route
					path="/login"
					element={
						<AppInfoContextProvider>
							<Login></Login>
						</AppInfoContextProvider>
					}
				/>

				<Route
					path="/:project?/:pid?/systems-library"
					element={
						<ProtectedAppPage>
							<SystemsLibraryMenu />
						</ProtectedAppPage>
					}
				/>

				<Route path="/:project?/:pid?/systems-library">
					<Route
						path="sensors/:systemId?"
						element={
							<ProtectedAppPage>
								<Sensors />
							</ProtectedAppPage>
						}
					/>
					<Route
						path="emitters/:systemId?"
						element={
							<ProtectedAppPage>
								<Emitters />
							</ProtectedAppPage>
						}
					/>
					<Route
						path="effectors/:systemId?"
						element={
							<ProtectedAppPage>
								<Effectors />
							</ProtectedAppPage>
						}
					/>
					<Route
						path="platforms/:systemId?"
						element={
							<ProtectedAppPage>
								<Platforms />
							</ProtectedAppPage>
						}
					/>
					<Route
						path="systems/:systemId?"
						element={
							<ProtectedAppPage>
								<Systems />
							</ProtectedAppPage>
						}
					/>
				</Route>

				<Route
					path="/project/:pid"
					element={
						<ProtectedAppPage>
							<ProjectMenu />
						</ProtectedAppPage>
					}
				/>
				<Route path="/project/:pid">
					<Route
						path="assessments/:assessmentId?/:scenarioId?"
						element={
							<ProtectedAppPage>
								<Assessments />
							</ProtectedAppPage>
						}
					/>
					<Route
						path="force-packages"
						element={
							<ProtectedAppPage>
								<ForcePackages />
							</ProtectedAppPage>
						}
					/>
					<Route
						path="force-packages/:fpId"
						element={
							<ProtectedAppPage>
								<ForcePackagesGraphPage />
							</ProtectedAppPage>
						}
					/>
				</Route>
				<Route
					path="resources"
					element={
						<ProtectedAppPage>
							<ResourcesMenu />
						</ProtectedAppPage>
					}
				/>
				<Route
					path="resources/symbols"
					element={
						<ProtectedAppPage>
							<SymbolsPage />
						</ProtectedAppPage>
					}
				/>
				<Route
					path="force-packages/:fpId"
					element={
						<ProtectedAppPage>
							<ForcePackagesGraphPage />
						</ProtectedAppPage>
					}
				/>

				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
