import { Echelon } from "../../../types/filters";

export const updateEchelonAgentIds = (echelon: Echelon, highestAgentId: number): [Echelon, number] => {
		if (echelon.systems) {
			echelon.systems.forEach((system) => {
				system.agent_id = highestAgentId + 1;
				highestAgentId++;
			});
		}
		if (echelon.echelons) {
			for (let i = 0; i < echelon.echelons.length; i++) {
				let [newEchelon, newHighestId] = updateEchelonAgentIds(echelon.echelons[i], highestAgentId);
				echelon.echelons[i] = newEchelon;
				highestAgentId = newHighestId;
			}
		}
		return [echelon, highestAgentId];
	};